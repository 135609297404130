
import {mapActions, mapGetters, mapMutations} from "vuex"
import {defineComponent} from "vue"
import {Field, Form} from 'vee-validate'
//@ts-ignore
import {VueTelInput} from 'vue3-tel-input'
import * as yup from 'yup';

export default defineComponent({
  name: "SendForm",
  components: {
    Field,
    Form,
    VueTelInput,
  },
  data() {
    return {
      phoneInput: {
        "countryCallingCode": "7",
        "nationalNumber": "",
        "number": "",
        "country": {
          "name": "",
          "iso2": "",
          "dialCode": ""
        },
        "countryCode": "",
        "valid": true,
        "formatted": "",
        "inputOptions.placeholder": 'test',
      },
      schema: yup.object({
        username: yup.string().min(2).required('Введите имя'),
        email: yup.string().required('Введите Email').email(),
        phone: yup.number().typeError('Введите номер телефона. Телефон может содержать только цифры')
      })
    }
  },
  computed: {
    ...mapGetters({
      sendFormUsername: 'sendFormUsername',
      sendFormEmail: 'sendFormEmail',
      sendFormPhone: 'sendFormPhone',
    }),
    username: {
      get(): string {
        return this.sendFormUsername
      },
      set(value: string): void {
        this.setSendFormUsername(value)
      },
    },
    email: {
      get(): string {
        return this.sendFormEmail
      },
      set(value: string): void {
        this.setSendFormEmail(value)
      },
    },
    phone: {
      get(): string {
        return this.sendFormPhone
      },
      set(value: string): void {
        this.setSendFormPhone(value)
      },
    },
  },
  methods: {
    ...mapActions({
      resetQuiz: 'resetQuiz',
      sendQuiz: 'sendQuiz',
    }),
    ...mapMutations({
      setSendFormUsername: 'setSendFormUsername',
      setSendFormEmail: 'setSendFormEmail',
      setSendFormPhone: 'setSendFormPhone',

    }),

    updatePhone(formatted: string, value: any) {
      console.log(this.schema)
      if (value) {
        let number = value?.number ?? ""
        this.setSendFormPhone(number)
        this.phone = number;
      }
    }
  },
})
