
import {defineComponent} from 'vue'
import {mapActions, mapGetters} from "vuex";
import Questions from "@/components/Questions.vue";
import MarkdownIt from 'markdown-it'

export default defineComponent({
  name: 'Quiz',
  components: {
    Questions
  },
  data: () => {
    return {
      selectedVariants: [],
    }
  },
  computed: {
    ...mapGetters({
      quiz: 'quiz',
      question: 'currentQuestion',
      index: 'quizCurrentQuestionIndex',
      selectedVariants: 'selectedVariants',
      selectedLength: 'selectedLength'
    }),
    percent() {
      //@ts-ignore
      return ((this.index) / (this.quiz.questions_count) * 100).toFixed(0);
    },
    description() {
      if (!this.question.description) {
        return null;
      }
      let bp = new MarkdownIt();
      let html = bp.render(this.question.description);

      let wrapper = document.createElement('div');
      wrapper.innerHTML = html;

      let links = wrapper.querySelectorAll('a');

      links.forEach((element, index) => {
          element.target = '_blank';
      })

      return wrapper.innerHTML;

    }
  },
  methods: {
    ...mapActions({
      showFailPage: 'showFailPage',
      nextQuestion: 'nextQuestion',
      previousQuestion: 'previousQuestion',
      selectVariantsLength: 'selectVariantsLength',

    }),
    nextQuestionCommon() {
      if (this.selectedLength < this.question.min_answers_amount) {
        this.$notify({
          title: 'Ошибка',
          type: 'error',
          text: `Минимальное кол-во выбранных вариантов ${this.question.min_answers_amount}`
        });
      }
      else {
        this.nextQuestion();
      }
    },
    selectVariants(variantId: number) {
      //@ts-ignore
      this.selectedVariants.push(variantId)
    }
  },

})
