import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08a7e68e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "question-block"
}
const _hoisted_2 = {
  key: 0,
  class: "uk-flex uk-flex-column uk-text-left question-block"
}
const _hoisted_3 = {
  key: 1,
  class: "uk-flex uk-flex-column uk-text-left question-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardRadio = _resolveComponent("CardRadio")!
  const _component_Radio = _resolveComponent("Radio")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.question.type === 'cards-radio')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_CardRadio)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.question.type === 'radio')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_Radio)
        ]))
      : _createCommentVNode("", true),
    (_ctx.question.type === 'checkbox')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Checkbox)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}