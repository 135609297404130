
import {defineComponent} from 'vue';
import Loading from '@/components/Loading.vue';
import {mapActions, mapGetters} from "vuex";

export default defineComponent({
  name: 'PollStartPage',
  components: {
    Loading,
  },
  methods: {
    ...mapActions({
      loadQuiz: 'loadQuiz',
      loadSecureQuiz: 'loadSecureQuiz',
      startQuiz: 'startQuiz',
      saveQuiz: 'saveQuiz',
      showFailPage: 'showFailPage',
    }),
    getStartText() {
      if (this.isTestable) {
        return 'Пройти тест';
      }

      return 'Пройти опрос';
    },
    getPollTypeName() {
      if (this.isTestable) {
        return 'Тест';
      }

      return 'Опрос';
    }
  },
  computed: {
    declination(): string {
      const text = ['вопрос', 'вопроса', 'вопросов']
      const cases = [2, 0, 1, 1, 1, 2]

      let index = (this.quiz.questions_count % 100 > 4 && this.quiz.questions_count % 100 < 20) ? 2 : cases[(this.quiz.questions_count % 10 < 5) ? this.quiz.questions_count % 10 : 5];

      return text[Number(index)]

    },
    getImage(): string {
      return this.quiz.images.start ? this.quiz.images.start : '/img/start.jpg'
    },
    ...mapGetters({
      quiz: 'quiz',
      quizIsReady: 'quizIsReady',
      isTestable: 'isTestable',

    }),

    isFinished: () => {
      return true;
    },
  },
  mounted() {
    window.document.title = 'Опрос'
    let urlParams = new URLSearchParams(window.location.search)
    let token = urlParams.get('access-token');

    localStorage.removeItem('access-token');

    if (token) {
      localStorage.setItem('access-token', token);
    }

    if (!urlParams.get('id')) {
      window.location.href = "https://moigektar.ru"
    }
    if (!urlParams.get('secure')) {
      this.loadQuiz(urlParams.get('id'))
    }
    else {
      this.loadSecureQuiz(urlParams.get('id'))
    }
  }
});
