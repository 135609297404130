import axios, {AxiosInstance} from "axios";

export default class ApiClient {
    static init(): AxiosInstance {

        return axios.create({
            baseURL: process.env.VUE_APP_API_POLLS_HOST,
            // headers: {
            //     common: {
            //         Accept: 'application/json; charset=UTF-8'
            //     }
            // }
        });
    }
}
