
import SendForm from '@/components/SendForm.vue';
import {defineComponent} from "vue";
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'Send',
  components: {
    SendForm,
  },
  computed: {
    ...mapGetters({
      quiz: 'quiz',
    }),
    getImage(): string {
      return this.quiz.images.finish ? this.quiz.images.start : '/img/finish.jpg'
    },
  },
})
