
import CardRadio         from '@/components/questions/CardRadio.vue'
import Checkbox          from '@/components/questions/Checkbox.vue'
import Radio             from '@/components/questions/Radio.vue'
import {mapGetters}      from "vuex";
import {defineComponent} from "vue";

export default defineComponent({
  name      : "Questions",
  components: {
    CardRadio,
    Checkbox,
    Radio
  },
  computed  : {
    ...mapGetters({
      question: 'currentQuestion'
    })
  }
})
