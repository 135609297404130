import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "uk-position-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_loaders_ball_clip_rotate_multiple = _resolveComponent("vue-loaders-ball-clip-rotate-multiple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_vue_loaders_ball_clip_rotate_multiple, {
      color: "#f0506e",
      scale: "3"
    })
  ]))
}