
import {defineComponent, watch} from 'vue';
import {mapActions, mapGetters} from "vuex";
import {useRoute} from "vue-router";
import Loading from "@/components/Loading.vue";
import {LoadingState} from "@/models/api/v1/LoadingState";
import {Api} from "@/types/api/v1/result";
import Variant = Api.V1.Testing.Variant;

export default defineComponent({
  name: 'Result',
  components: {Loading},
  setup() {
    const route = useRoute()

    return {
      // with params.id
      id: route.params.id
    }
  },
  methods: {
    ...mapActions({
      catchError: 'catchError',
      loadResult: 'loadResult',
      resultLoadingState: 'resultLoadingState',
    }),
    getVariantColor(variant: Variant): string {
      switch (variant.variant_correctness_state) {
        case 'danger':
          return 'variant-invalid uk-text-danger uk-text-bold'
        case 'success':
          return 'variant-valid uk-text-success uk-text-bold'
        default:
          return 'test';
      }
    }
  },
  computed: {
    LoadingState() {
      return LoadingState
    },
    ...mapGetters({
      resultLoadingState: 'resultLoadingState',
      resultPageTitle: 'resultPageTitle',
      result: 'result',

    }),

  },
  mounted() {
    console.log('mounted')
    this.loadResult(this.id);
    window.document.title = this.resultPageTitle;

    watch(() => this.resultPageTitle, () => {
      window.document.title = this.resultPageTitle;
    });

  }
})
