
import {defineComponent} from 'vue';
import Header from '@/components/Header.vue'; // @ is an alias to /src
import {mapGetters} from "vuex";

export default defineComponent({
  components: {
    Header
  },
  computed: {
    ...mapGetters({
      currentView: 'currentView',
    }),
  },
});
