import {AxiosInstance, AxiosResponse} from "axios";
import ApiClient from "@/services/client/ApiClient";

export default class TestingFetcher {
  private _client: AxiosInstance;

  constructor() {
    this._client = ApiClient.init()
  }

  public getResult(userPollId: number):Promise<AxiosResponse> {
    return this._client.get(`/testing/result/${userPollId}`, {withCredentials: true});
  }
}