<template>
  <div>
    <div class="uk-container">
        <div class="uk-text-center">
          <img src="/img/success.svg" alt="">
          <p class="uk-h1">спасибо! Ваш голос учтен</p>
<!--          <p class="uk-h3">Поучаствуйте в других опросах проекта</p>-->
        </div>
<!--        <div class="uk-flex uk-flex-between uk-flex-middle more-question">-->
<!--          <div>Формат взаимодействия собственников для наиболее эффективного управления и развития общей инфраструктуры.</div>-->
<!--          <div>-->
<!--            <a href="/quiz" class="uk-button-danger uk-button outline">Пройти опрос</a>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="uk-flex uk-flex-between uk-flex-middle more-question">-->
<!--          <div>Формат взаимодействия собственников для наиболее эффективного управления и развития общей инфраструктуры.</div>-->
<!--          <div>-->
<!--            <a href="/quiz" class="uk-button-danger uk-button outline">Пройти опрос</a>-->
<!--          </div>-->
<!--        </div>-->
    </div>
  </div>
</template>


<style lang="scss" scoped>
.uk-h1{
  font-family: "BebasNeue";
  font-weight: bold;
  color: #274562;
}
.uk-h3{
  color: #274562;
  font-family: "BebasNeue";
  font-weight: bold;
}
.more-question{
  padding: 15px;
  border: 1px solid #D7E2EA;
  color: #4F4F4F;
  font-size: 1.1rem;
  margin-bottom: 15px;
}
</style>
