import Vue, {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'uikit/dist/css/uikit.css'
import 'vue-loaders/dist/vue-loaders.css';

import {email, min, required} from '@vee-validate/rules';
import {configure, defineRule} from 'vee-validate';
import ru from '@vee-validate/i18n/dist/locale/ru.json';
import {localize} from "@vee-validate/i18n";
import Notifications from '@kyvg/vue3-notification'

//@ts-ignore
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'


import * as Sentry from "@sentry/vue";

import VueLoaders from 'vue-loaders';


defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

configure({
  generateMessage: localize({
    ru
  }),
});

const app = createApp(App)
    .use(store)
    .use(router)
    .use(Notifications)
    .use(VueTelInput)
    .use(VueLoaders)

Sentry.init({
  app,
  dsn: "https://5107322f8ed0f15078d5c7fcf0899556@sentry.bug.land/20",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
});

if (process.env.NODE_ENV !== 'production' && typeof console !== 'undefined') {
  app.config.warnHandler = function (msg: string, vm: any, trace: string) {
    console.error(`[Vue warn]: ${msg}${trace}`)
  };
}

app.mount('#app')

