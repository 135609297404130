
import {defineComponent} from 'vue';
import {mapGetters} from "vuex";

export default defineComponent({
  name: 'Fail',
  computed: {
    ...mapGetters({
      message: 'errorMessage',
    }),
  }
});
