import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import PollsFetcher from "@/services/fetch/PollsFetcher"
import {Api, Poll} from "@/types/api/v1/poll";
import  "@/router";
import {Feature} from "@/models/api/v1/Feature";
import router from "@/router";
import Quiz = Api.V1.Quiz;

@Module
export default class SendForm extends VuexModule {
  private _username: string = ""
  private _email: string = ""
  private _phone: string = ""

  get sendFormUsername(): string {
    return this._username;
  }

  get sendFormEmail(): string {
    return this._email;
  }

  get sendFormPhone(): string {
    return this._phone;
  }

  @Mutation
  setSendFormUsername(username: string) {
    this._username = username;
  }

  @Mutation
  setSendFormEmail(email: string) {
    this._email = email;
  }

  @Mutation
  setSendFormPhone(phone: string) {
    this._phone = phone;
  }

  @Action
  sendQuiz() {
    let answers = this.context.rootState.Quiz._answers
    let quizId = this.context.rootState.Quiz._quiz.id
    let quiz = this.context.getters['quiz'];

    let user: Poll.Credentials = {
      "name": this._username,
      "email": this._email,
      "phone": this._phone,
    }

    let data: Api.V1.SendForm = {
      "answers": answers,
      "credentials": user
    }

    let fetcher = new PollsFetcher()
    fetcher.send(quizId, data).then((response: any) => {
      if (response.status !== 200) {

        // Sentry.captureMessage(response.data.message, {
        //     extra: {
        //         auth: response.data
        //     }
        // })

        this.context.dispatch('showFailPage').catch()
        return
      }
        if (quiz?.features.indexOf(Feature.ID_TESTING) !== -1) {
          console.log('if worker')
          router.push({path: `/result/${response.data[0].id}`}).catch();
        }


      this.context.dispatch('showSuccessPage').catch()

      //reload iframe parent page
      window.parent.postMessage({
        type: 'SEND_QUIZ_FORM'
      }, '*');
    }).catch((err) => {
      // Sentry.captureMessage('Ошибка отправки ответов', {
      //     extra: {
      //         response: err.response.data,
      //         data: data
      //     }
      // })
      this.context.dispatch('showFailPage').catch()
    })
  }
}