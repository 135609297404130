import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators'
import {Api, Poll} from "@/types/api/v1/poll";
import Variant = Poll.Variant;

@Module
export default class Question extends VuexModule {
    private _question: undefined | Api.V1.Question

    // todo: переделать на объект
    private _selectedVariants: Array<number> = []
    private _variantValue: undefined | string
    private _selectedLength: number = 0

    get selectedVariants(): Array<number> {
        return this._selectedVariants;
    }

    get variantValue(): undefined | string {
        return this._variantValue;
    }

    get currentQuestion(): undefined | Api.V1.Question {
        return this._question;
    }
    get selectedLength(): number {
        return this._selectedLength;
    }

    @Mutation
    setSelectedVariants(variants: Array<number>) {
        this._selectedVariants = variants;
    }

    @Mutation
    setSelectedVariantValue(value: string) {
        this._variantValue = value;
    }

    @Mutation
    setSelectedLength(length: number) {
        this._selectedLength = length;
    }

    @Mutation
    setQuestion(question: Api.V1.Question) {
        this._question         = question;
        this._selectedVariants = [];
        this._variantValue     = undefined;
    }

    @Action
    selectVariants(variantIds: Array<number>) {
        this.context.commit('setSelectedVariants', variantIds)
        this.context.commit('setErrorMessage', '')
    }
    @Action
    selectVariantsLength(length: number) {
        this.context.commit('setSelectedLength', length)
        this.context.commit('setErrorMessage', '')
    }
    @Action
    setVariantValue(value: string) {
        this.context.commit('setSelectedVariantValue', value)
        this.context.commit('setErrorMessage', '')
    }

    @Action
    answer() {
        let variants = this._selectedVariants ?? [];

        if (variants.length == 0 && this._question?.is_required) {
            throw new Error("Variants cannot be empty");
        }
        if (this._question == undefined) {
            throw new Error("Question is empty");
        }

        let answer: Poll.Answer = {
            question: this._question.id,
            variants: variants.map((variant): Variant => {
                return {
                    id: variant,
                    value: this._variantValue,
                }
            }),
        }

        this.context.commit('addAnswer', answer);
    }
}