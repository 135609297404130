import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Result from "@/views/Result.vue";
import Poll from "@/views/poll/Poll.vue";
import NotFound from "@/views/NotFound.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: Poll,
  },
  {
    path: '/result/:id',
    component: Result,
    props: true
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/:catchAll(.*)',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
