
import {mapActions, mapGetters} from "vuex";
import {computed, defineComponent} from "vue";

export default defineComponent({
  name    : "CardRadio",
  computed: {
    ...mapGetters({
      question        : 'currentQuestion',
      error           : 'errorMessage',
      selectedVariants: 'selectedVariants',
    }),
    selectedVariant: {
      get: function (): number | null {
        if (this.selectedVariants?.length > 0) {
          return this.selectedVariants[0]
        }
        return null
      },
      set: function (value: number) {
        this.selectVariants([value]);
        this.selectVariantsLength(1);

        setTimeout(() => {
          this.nextQuestion()
        }, 500)
      }
    }
  },
  methods: {
    ...mapActions({
      selectVariants: 'selectVariants',
      nextQuestion  : 'nextQuestion',
      selectVariantsLength: 'selectVariantsLength',
    }),
  },
})
