import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "uk-container" }
const _hoisted_2 = { class: "uk-card uk-card-default" }
const _hoisted_3 = { class: "uk-grid" }
const _hoisted_4 = { class: "uk-width-1-2@m" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SendForm = _resolveComponent("SendForm")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", {
              class: "uk-background-cover uk-height-1-1 uk-flex uk-flex-center uk-flex-middle",
              style: _normalizeStyle({ backgroundImage: `url(${_ctx.getImage})` })
            }, null, 4)
          ]),
          _createVNode(_component_SendForm)
        ])
      ])
    ])
  ]))
}