
import {mapActions, mapGetters} from "vuex";
import {defineComponent} from "vue";
import Vue from 'vue'


export default defineComponent({
  name: "Checkbox",
  computed: {
    ...mapGetters({
      question: 'currentQuestion',
      error: 'errorMessage',
      selectedVariants: 'selectedVariants',
      variantValue: 'variantValue',
      selectedLength: 'selectedLength'
    }),
    selectedVariantIds: {
      get: function (): Array<number> | null {
        if (this.selectedVariants?.length > 0) {
          return this.selectedVariants
        }
        return []
      },
      set: function (values: Array<number>) {

        this.selectVariantsLength(values?.length);

        if (this?.selectedLength > this.question.max_answers_amount) {
          this.$notify({
            title: 'Ошибка',
            type: 'error',
            text: `Максимально кол-во выбранных вариантов ${this.question.max_answers_amount}`
          });
        } else {
          this.selectVariants(values);
        }
      }
    },
    selectedVariantValue: {
      get: function (): string {
        return this.variantValue;
      },
      set: function (value: string) {
        this.setVariantValue(value);
      }
    },
  },
  methods: {
    ...mapActions({
      selectVariants: 'selectVariants',
      setVariantValue: 'setVariantValue',
      selectVariantsLength: 'selectVariantsLength',
      notification: 'notification'
    }),
  },
})
