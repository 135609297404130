
import {mapActions, mapGetters} from "vuex";
import {defineComponent}        from "vue";

export default defineComponent({
  name    : "Radio",
  computed: {
    ...mapGetters({
      question        : 'currentQuestion',
      error           : 'errorMessage',
      selectedVariants: 'selectedVariants',
      variantValue    : 'variantValue',

    }),
    selectedVariant: {
      get: function (): number | null {

        if (this.selectedVariants?.length > 0) {
          return this.selectedVariants[0]
        }
        return null
      },
      set: function (value: number) {
        this.selectVariantsLength(1);
        this.selectVariants([value]);

        // setTimeout(() => {
        //   this.nextQuestion()
        // }, 400)
      }
    },
    selectedVariantValue: {
      get: function (): string {
        return this.variantValue;
      },
      set: function (value: string) {
        this.setVariantValue(value);
      }
    },
  },

  methods : {
    onChange(event:any, type:string){
      if(type !== 'text'){
        setTimeout(() => {
          this.nextQuestion()
        }, 400)
      }
    },
    ...mapActions({
      selectVariants: 'selectVariants',
      nextQuestion  : 'nextQuestion',
      setVariantValue: 'setVariantValue',
      selectVariantsLength: 'selectVariantsLength',
    }),
  },
})
